import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async";
import UserHero from '../components/UserHero';
import '../assets/css/admin.css';
import { ThemeContext, themes } from '../providers/ThemeProvider';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ApiUrl } from '../utils/constants'
import UserLinkDisplay from '../components/UserLinkDisplay';
import SkeletonUserHero from '../components/SkeletonUserHero';
import SkeletonUserLinks from '../components/SkeletonUserLinks';
import ToggleMode from '../components/ToggleMode';
import NewUserMode from '../components/NewUserMode';
import { PreviewContext } from '../providers/PreviewProvider';

const UserHome = () => {
  const navigate = useNavigate();
  const { userName } = useParams();
  const { theme } = useContext(ThemeContext);
  const { isNewUserMode, setIsNewUserMode } = useContext(PreviewContext);
  const [fullName, setFullName] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [userBio, setUserBio] = useState<string>('');
  const [updatedUserBio, setUpdatedUserBio] = useState<string>('');
  const [socialHandles, setSocialHandles] = useState<Array<any>>([]);
  const [userLinks, setUserLinks] = useState<Array<any>>([]);
  const [isNewsLetter, setIsNewsLetter] = useState<boolean>(false);
  const [newsletterValue, setNewsletterValue] = useState<string>('');
  const [newsletterHeading, setNewsletterHeading] = useState<string>('');
  const [newsletterBody, setNewsletterBody] = useState<string>('');
  // const [lat, setLat] = useState<number>(0);
  // const [lng, setLng] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ip,setIP] = useState('');
    
  //creating function to load ip address from the API
  const getData = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/');
      setIP(res.data.IPv4);
  }

  const getDevices = () => {
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid: string = `${navigator_info.mimeTypes.length}`;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    return uid;
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (theme) {
      if (theme === themes.dark) {
      document.body.setAttribute('data-theme', 'dark');
      }
      if (theme === themes.light) {
      document.body.setAttribute('data-theme', '');
      }
    } else {
      document.body.setAttribute('data-theme', '');
    }
  }, [theme]);

  // const showPosition = (position: any) => {
  //   setLat(position.coords.latitude);
  //   setLng(position.coords.longitude);
  // }

  // const getUsersCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   }
  // }

  const getUsersDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  }

  const addPageViewToAnalytics = async() => {
    // if (lat !== 0 && lng !== 0) {
      const data = {
        deviceId: getDevices(),
        ipAddress: ip,
        // longitude: lng !== 0 ? `${lng}` : '0',
        // latitude: lat !== 0 ? `${lat}` : '0',
        deviceType: getUsersDeviceType(),
        userUrl: `${window.location.origin}/${userName}`
      }
      try {
        const response = await axios.post(`${ApiUrl}/analytic/pageview`, data);
        if (response.data.status === true) {
          console.log(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    // }
  }

  const getCurrentUserProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${ApiUrl}/${userName}`);
      if (response) {
        setIsLoading(false);
        if (response.data.status === true) {
          const responseData = response.data.data;
          if (responseData.lastname !== null) {
            setFullName(`${responseData.firstname} ${responseData.lastname}`);
          } else if (responseData.firstname !== null) {
            setFullName(`${responseData.firstname}`);
          } else {
            setFullName('');
          }
          // getUsersCurrentLocation();
          addPageViewToAnalytics();
          setCoverImage(responseData.coverphoto);
          setAvatar(responseData.avatar);
          setUserBio(responseData.bio);
          if (responseData.bio !== '') {
            setUpdatedUserBio(responseData.bio.replace(/<[^>]+>/g, ''));
          }
          setSocialHandles(responseData.socialMediaHandles);
          setUserLinks(responseData.userContent);
          setNewsletterValue(responseData.newsletterChannel);
          setNewsletterHeading(responseData.newsletterHeading);
          setNewsletterBody(responseData.newsletterBody);
          if (responseData.isNewsLetter === 0) {
            setIsNewsLetter(false);
          } else {
            setIsNewsLetter(true);
          }

        }
      }
    } catch (e: any) {
      setIsLoading(false);
      if (e.response.status === 401) {
        if (window.location.pathname.includes('signup') && window.location.search !== '') {
          navigate(`/signup${window.location.search}`);
        } else {
          navigate('/not-found');
        }
      }
    }
  }

  useEffect(() => {
    if (userName) {
      getCurrentUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, ip]);

  useEffect(() => {
    setIsNewUserMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={avatar} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0" />
        <title>{fullName}</title>
        <meta name="description" content={updatedUserBio} />
        <link rel="apple-touch-icon" href={avatar} />
        <meta property="og:title" content={fullName} />
        <meta property="og:description" content={updatedUserBio} />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:title" content={fullName} />
        <meta property="twitter:creator" content={fullName} />
        <meta property="twitter:description" content={updatedUserBio} />
      </Helmet>
      <div className="admin-home__wrapper">
        {!isLoading ? (
          <UserHero
            fullName={fullName}
            coverImage={coverImage}
            userBio={userBio}
            socialHandles={socialHandles}
            avatar={avatar}
            devices={getDevices()}
          />
        ): (
          <SkeletonUserHero />
        )}
        {!isLoading ? (
          <UserLinkDisplay
            userLinks={userLinks}
            isSubscribe={isNewsLetter}
            newsChannel={newsletterValue}
            newsHeading={newsletterHeading}
            newsBody={newsletterBody}
            devices={getDevices()}
          />
        ) : (
          <SkeletonUserLinks />
        )}
        {isNewUserMode ? (
          <NewUserMode />
        ) : null}
      </div>
      <ToggleMode />
    </>
  )
}

export default UserHome
