import React, { createContext, useEffect, useState } from 'react'

export const themes = {
    dark: "dark",
    light: "",
  };

export const ThemeContext = createContext<any>({});

const ThemeProvider = (props: { children: any }) => {
  const [theme, setTheme] = useState<string>(themes.light);

  const changeTheme = (theme: string) => {
    setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  useEffect(() => {
    if (localStorage.getItem('theme') !== null) {
      const currentTheme: string | null = localStorage.getItem('theme') || '';
      setTheme(currentTheme);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider