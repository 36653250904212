import React from 'react'

type Props = {
  messages: Array<any>,
  messageType: string,
  viewContent: Function
}

const MessagesList = ({ messages, messageType, viewContent }: Props) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const getMonthAndDay = (date: string) => {
    const dateObj = new Date(date);
    const month = months[dateObj.getMonth()];
    const day = dateObj.getDate();
    return `${day} ${month}`;
  }

  const viewContentOfMessage = (item: any) => {
    if (messageType === 'draft') {
      viewContent(item);
    }
  }

  return (
    <div className="subscribers-list__wrapper">
      <div className="subscribers-list">
        {messages.length > 0 && messages.map((item: any) => (
          <div className={messageType === 'draft' ? "subscriber messages click" : "subscriber messages"} key={item.uid} onClick={() => viewContentOfMessage(item)}>
            <div className="user messages">
              <div className="user-details">
                <p className="details-title">
                  {item.subject}
                </p>
                <p className='details-body' dangerouslySetInnerHTML={{ __html: `${item.body}` }}></p>
                <p className='details-info'><span>To: </span>{item.channel === 'email' ? 'Email Subscribers' : 'WhatsApp Subscribers'}</p>
              </div>
              <div className="date-section">
                <p className="date">{getMonthAndDay(item.created_at)}</p>
              </div>
            </div>
          </div>
        ))}
        {messages.length === 0 ? (
          <div className="no-subscriber-section">
            <p>You have no messages yet. When you do, they&apos;ll show up here.</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default MessagesList