import React from 'react'
import EmptyImage from '../assets/icons/no-image.svg'
import '../assets/css/admin.css'
import WhatsApp from '../assets/icons/whatsapp.svg'
import BehanceImg from '../assets/icons/behance.svg'
import TikTokImg from '../assets/icons/tiktok.svg'
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'react-feather'
import axios from 'axios'
import { ApiUrl } from '../utils/constants'

type Props = {
  coverImage: string,
  avatar: string,
  fullName: string,
  userBio: string,
  socialHandles: Array<any>,
  devices: string
}

const UserHero = ({ coverImage, avatar, fullName, userBio, socialHandles, devices }: Props) => {

  const addLinkViewAnalytics = async (index: string) => {
    if (devices !== '') {
      const data = {
        socialMediaUid: index,
        deviceId: devices
      }
      try {
        const response = await axios.post(`${ApiUrl}/analytic/content-media`, data);
        if (response.data.status === true) {
          console.log('success');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  
  const handleContentShowing = (e: React.MouseEvent<HTMLDivElement>, value: any) => {
    window.open(value.url);
    addLinkViewAnalytics(value.uid);
  }

  return (
    <div className="page-hero__wrapper">
      {coverImage !== '' && coverImage !== null ? (
        <div className="hero-cover-image" style={{ backgroundImage:`url(${coverImage})`}} />
      ) : (
        <div className="hero-image__wrapper">
            <img src={EmptyImage} alt="" />
        </div>
      )}
      <div className="user-profile__wrapper">
        <div className="user-avatar__section">
          {avatar !== '' && avatar !== null ? (
            <div className="avatar-image" style={{ backgroundImage:`url(${avatar})`}} />
          ) : (
            <div className="avatar-image__wrapper">
              <img src={EmptyImage} alt="" />
            </div>
          )}
        </div>
        <div className="user-bio__section">
          <h5>{fullName}</h5>
          {userBio !== '' ? (
            <p dangerouslySetInnerHTML={{__html: userBio}} />
          ) : null}
          {socialHandles.length > 0 ? (
            <div className="user-social__handles">
              {socialHandles.map((item: any) => (
                <div key={item.uid} onClick = {(e: any) => handleContentShowing(e, item)}>
                  {item.name === 'facebook' ? (
                    <div className="social-icon">
                      <Facebook />
                    </div>
                  ) : null}
                  {item.name === 'linkedin' ? (
                    <div className="social-icon">
                      <Linkedin />
                    </div>
                  ) : null}
                  {item.name === 'twitter' ? (
                    <div className="social-icon">
                      <Twitter />
                    </div>
                  ) : null}
                  {item.name === 'instagram' ? (
                    <div className="social-icon">
                      <Instagram />
                    </div>
                  ) : null}
                  {item.name === 'whatsapp' ? (
                    <div className="social-icon">
                      <img src={WhatsApp} alt={item.url} />
                    </div>
                  ) : null}
                  {item.name === 'behance' ? (
                    <div className="social-icon">
                      <img src={BehanceImg} alt={item.url} />
                    </div>
                  ) : null}
                  {item.name === 'tiktok' ? (
                    <div className="social-icon">
                      <img src={TikTokImg} alt={item.url} />
                    </div>
                  ) : null}
                  {item.name === 'youtube' ? (
                    <div className="social-icon">
                      <Youtube />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default UserHero