import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import React from 'react'
import { Navigate, Outlet } from 'react-router';

const PrivateRoute = () => {
  let isAuthenticated:boolean = false;
  if (Cookies.get('_apitoken') !== null && Cookies.get('_apitoken') !== undefined) {
    isAuthenticated = true;
  } else {
    toast.error('You are currently not logged in');
    isAuthenticated = false;
  }
  return isAuthenticated ? <Outlet /> : (window.location.pathname !== "/login" && window.location.pathname !== "/signup" ? <Navigate to="/login" /> : <Navigate to={`${window.location.pathname}`} />);
}

export default PrivateRoute
