import React, { useEffect, useState } from 'react'
import LogoImage from '../assets/images/app-logo.png'

const AppLoader = () => {
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setPercent(percent => (percent < 100 ? percent + 10 : 100));
    }, 200);
    return () => {};
  });

  return (
    <div className="app-loader__wrapper">
        <div className="app-loader__container">
            <div className="loader-image">
                <img src={LogoImage} alt="Pagechap" />
            </div>
            <div className="loader-progress">
                <div className="child-element" style={{ width: `${percent}%`}} />
            </div>
        </div>
    </div>
  )
}

export default AppLoader