import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotFound from '../assets/images/not-found.jpg'

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBackToHomePage = () => {
    navigate('/');
  }

  return (
    <div className='error-container'>
      <div className="error-page__wrapper">
        <div className="error-page__content">
          <div className="error-image">
            <img src={NotFound} alt="" />
          </div>
          <div className="error-page__cta">
            <button onClick={goBackToHomePage}>
              Go To Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound