import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, Search, Trash2 } from 'react-feather'
import MessagesList from '../../components/MessagesList'
import { ApiUrl } from '../../utils/constants'
import '../styles.css'
import MessageDetails from './MessageDetails'

type Props = {
  changeDisplay: Function
}

const AllMessages = ({ changeDisplay }: Props) => {
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('draft-tab');
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>('Messages');
  const [draftMessages, setDraftMessages] = useState<any>([]);
  const [sentMessages, setSentMessages] = useState<any>([]);
  const [allMessages, setAllMessages] = useState<any>([]);
  const [message, setMessage] = useState<any>({});

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  }

  const handleSearchDisplay = () => {
    setIsSearch(!isSearch);
  }

  const getAllMessages = async () => {
    const response = await axios.get(`${ApiUrl}/user/newsletter-logs`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('_apitoken')}`
      }
    });
    if (response) {
      if (response.data.status === true) {
        const responseData = response.data.data.data;
        setAllMessages(responseData);
        setIsDelete(false);
        setPageTitle('Messages');
      }
    }
  }

  const getDraftMessages = async () => {
    const response = await axios.get(`${ApiUrl}/user/newsletter-logs?status=draft`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('_apitoken')}`
      }
    });
    if (response) {
      if (response.data.status === true) {
        const responseData = response.data.data.data;
        setDraftMessages(responseData);
        setIsDelete(false);
        setPageTitle('Messages');
      }
    }
  }

  const getSentMessages = async () => {
    const response = await axios.get(`${ApiUrl}/user/newsletter-logs?status=sent`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('_apitoken')}`
      }
    });
    if (response) {
      if (response.data.status === true) {
        const responseData = response.data.data.data;
        setSentMessages(responseData);
        setIsDelete(false);
        setPageTitle('Messages');
      }
    }
  }

  const displayMessageDetails = (value: any) => {
    setIsDefault(false);
    allMessages.forEach((item: any) => {
      if (item.uid === value.uid) {
        setMessage(value);
      }
    });
  }

  const handleRevertToDefaultDisplay = () => {
    setIsDefault(true);
  }

  useEffect(() => {
    getDraftMessages();
    getSentMessages();
    getAllMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {isDefault ? (
    <div className="edit-profile__wrapper">
      <div className="back-header__wrapper">
        <div className="back-header__container">
          <div className="back-btn" onClick={() => changeDisplay()}>
            <ArrowLeft />
          </div>
          <div className="page-title flex">
            <h5>{pageTitle}</h5>
            {isDelete ? (
              <button className="title-action">
                <Trash2 />
              </button>
            ) : (
              <button className="title-action" onClick={handleSearchDisplay}>
                <Search />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="newsletter-section">
        <div className="admin-details__wrapper">
          <div className="admin-details__content">
            <div className="tab-header__section section-one">
              <div className={activeTab === 'draft-tab' ? "header-one active" : "header-one"} onClick={() => handleTabChange('draft-tab')}>
                Drafts ({draftMessages.length})
              </div>
              <div className={activeTab === 'sent-tab' ? "header-three active" : "header-three"} onClick={() => handleTabChange('sent-tab')}>
                  Sent ({sentMessages.length})
                </div>
              </div>
              {isSearch ? (
                <div className="search-section">
                  <div className="search-icon">
                    <Search />
                  </div>
                  <div className="search-section__wrapper">
                    <input type="text" name="" id="" placeholder='Search Messages' />
                  </div>
                </div>
              ) : null}
              <div className="tab-content__section">
                {activeTab === 'draft-tab' ? (
                  <MessagesList messages={draftMessages} messageType="draft" viewContent={displayMessageDetails} />
                ) : null}
                {activeTab === 'sent-tab' ? (
                  <MessagesList messages={sentMessages} messageType="sent" viewContent={displayMessageDetails} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
    <MessageDetails changeDisplay={handleRevertToDefaultDisplay} message={message} />
    )}
    </>
  )
}

export default AllMessages