import axios from 'axios';
import Cookies from 'js-cookie';
import React, { createContext, useEffect, useState } from 'react'
import { ApiUrl } from '../utils/constants';

export const UserDetailsContext = createContext<any>({});

const UserDetailsProvider = (props: { children: any }) => {
  const [newData, setNewData] = useState<Array<any>>([]);
  const [isNewsLetter, setIsNewsLetter] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [allCountries, setAllCountries] = useState<Array<any>>([]);

  const addNewItem = (type: string) => {
    let data = {}
    if (type === 'link_block') {
      data = {
        name: '',
        link: ''
      }
    } else if (type === 'text_block') {
      data = {
        title: '',
        text: '' 
      }
    } else if (type === 'image_block') {
      data = {
        title: '',
        image: '',
        text: ''
      }
    } else if (type === 'video_block') {
      data = {
        title: '',
        video: '',
        text: ''
      }
    }
    setNewData([ ...newData, data]);
  }

  const removeLinkItem = (index: number) => {
    const data: Array<any> = [];
    newData.forEach((item: any, i: number) => {
      if (i !== index) {
        data.push(item);
      }
    })
    setNewData(data);
  }

  const getCurrentUser = async () => {
    const appToken = Cookies.get('_apitoken');
    if (appToken !== null && appToken !== undefined) {
      try {
        const response = await axios.get(`${ApiUrl}/me`, {
          headers: {
            Authorization: `Bearer ${appToken}`
          }
        })
        if (response) {
          setCurrentUser(response.data.data);
          if (response.data.data.newsLetter === 0) {
            setIsNewsLetter(false);
          } else {
            setIsNewsLetter(true);
          }
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          Cookies.remove('_apitoken');
        }
      }
    }
  }

  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserDetailsContext.Provider value={{
        allCountries,
        setAllCountries,
        addNewItem,
        newData,
        setNewData,
        isNewsLetter,
        setIsNewsLetter,
        currentUser,
        removeLinkItem
    }}>
        {props.children}
    </UserDetailsContext.Provider>
  )
}

export default UserDetailsProvider