import React from 'react'
import BackHeader from '../../components/BackHeader'
import WhatsApp from '../../assets/icons/whatsapp-black.svg'
import '../styles.css'
import { ChevronRight, Mail } from 'react-feather'

type Props = {
    changeDisplay: Function,
    composeMessage: Function
}

const MessageType = ({ changeDisplay, composeMessage }: Props) => {
  return (
    <div className="edit-profile__wrapper">
      <BackHeader title="Select Message Type" isPreviousPage={false} changeDisplay={changeDisplay} />
      <div className="newsletter-section">
        <div className="admin-details__wrapper">
          <div className="admin-details__content">
            <div className="newsletter-card" onClick={() => composeMessage('email')}>
              <div className="card-content one-text">
                <div className="card-icon">
                  <Mail />
                </div>
                <div className="card-title">
                  <h4>Email Message</h4>
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div>
            <div className="newsletter-card">
              <div className="card-content one-text">
                <div className="card-icon">
                  <img src={WhatsApp} alt="whatsapp" />
                </div>
                <div className="card-title">
                  <h4>Whatsapp Message <span>Coming Soon</span></h4>
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageType