import React from 'react'
import '../assets/css/accordion.css'

const SkeletonUserLinks = () => {
  return (
    <div className="link-display__wrapper">
      <div className="accordion__wrapper">
        <div className="accordion-item">
          <div className="accordion-title skeleton" />
        </div>
        <div className="accordion-item">
          <div className="accordion-title skeleton" />
        </div>
        <div className="accordion-item">
          <div className="accordion-title skeleton" />
        </div>
        <div className="accordion-item">
          <div className="accordion-title skeleton" />
        </div>
      </div>
    </div>
  )
}

export default SkeletonUserLinks