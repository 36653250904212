import React, { useState } from 'react'
import BackHeader from '../../components/BackHeader'
import ComposeMessage from './ComposeMessage'

type Props = {
  changeDisplay: Function,
  message: any
}

const MessageDetails = ({ changeDisplay, message }: Props) => {
  const [isDefault, setIsDefault] = useState<boolean>(true);

  const handleDisplayChange = () => {
    setIsDefault(!isDefault);
  }
  return (
    <>
    {isDefault ? (
      <div className="edit-profile__wrapper">
        <BackHeader title="Message Details" isPreviousPage={false} changeDisplay={changeDisplay} />
        <div className="newsletter-section">
          <div className="admin-details__wrapper">
            <div className="admin-details__content">
              <div className="select-container">
                <span>To:</span>
                <select value={message.channel !== null ? message.channel : "email"} disabled>
                  <option value="">Select</option>
                  <option value="whatsapp">WhatsApp Subscribers</option>
                  <option value="email">Email Subscribers</option>
                </select>
              </div>
              <div className="message-details__container">
                <div className="details-content">
                  <div className="subject-section">
                    <p className="message-title">
                      {message.subject}
                    </p>
                  </div>
                  <div className="body-section">
                    <p dangerouslySetInnerHTML={{ __html: `${message.body}` }}></p>
                  </div>
                </div>
              </div>
              <button className='edit-btn' onClick={handleDisplayChange}>
                Continue Editing
              </button>
              {/* <button className='delete-btn'>
                Delete Message
              </button> */}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <ComposeMessage messageMode={message.channel} messageContent={message} changeDisplay={handleDisplayChange} />
    )}
    </>
  )
}

export default MessageDetails