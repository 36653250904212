import React, { createContext, useState } from 'react'

export const PreviewContext = createContext<any>({});

const PreviewProvider = (props: { children: any }) => {
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [isNewUserMode, setIsNewUserMode] = useState<boolean>(true);

  return (
    <PreviewContext.Provider value={{ isPreviewMode, isNewUserMode, setIsPreviewMode, setIsNewUserMode }}>
        {props.children}
    </PreviewContext.Provider>
  )
}

export default PreviewProvider