import React from 'react'
import { X } from 'react-feather'
import '../assets/css/modal.css'

type Props = {
  closeModal: Function,
  imageUrl: string
}
const ViewImageModal = ({ closeModal, imageUrl }: Props) => {
  return (
    <div className="image-modal__wrapper">
      <div className="image-modal__content">
        <div className="modal-body">
          <div className="image-container" style={{ backgroundImage: `url(${imageUrl})`}} />
        </div>
        <div className="modal-footer">
          <div className="close-btn" onClick={() => closeModal()}>
            <X />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewImageModal