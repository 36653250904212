import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import BackHeader from '../../components/BackHeader'
import ComposeNewsletter from '../../components/ComposeNewsletter'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import axios from 'axios'
import { ApiUrl } from '../../utils/constants'

type Props = {
  changeDisplay: Function,
  messageMode: string,
  messageContent: any
}
const ComposeMessage = ({ changeDisplay, messageMode, messageContent }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<any>(EditorState.createEmpty());
  const [draftId, setDraftId] = useState<string>('');

  const saveMessageToDrafts = async () => {
    if (!isSubmitted && message.getCurrentContent().getPlainText().length !== 0) {
      const rawContentState = convertToRaw(message.getCurrentContent());
      const markup = draftToHtml(
        rawContentState
      );
      const data = {
        subject,
        body: markup,
        channel: messageMode
      };
      const url = '/user/create-draft';
      try {
        const response = await axios.post(`${ApiUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get('_apitoken')}`
          }
        })
        if (response) {
          if (response.data.status === true) {
            toast.success('Message saved to Draft');
            changeDisplay();
          }
        }
      } catch (e) {
        console.log(e);
      } 
    } else {
      changeDisplay();
    }
  }

  useEffect(() => {
    if (messageContent !== null) {
      const blocksFromHtml = htmlToDraft(messageContent.body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setMessage(editorState);
      setSubject(messageContent.subject);
      setDraftId(messageContent.uid);
    }
  }, [messageContent]);

  return (
    <div className="edit-profile__wrapper">
      <BackHeader title="Compose Message" isPreviousPage={false} changeDisplay={saveMessageToDrafts} />
      <div className="newsletter-section">
        <div className="admin-details__wrapper">
          <div className="admin-details__content">
            <ComposeNewsletter
              mode={messageMode}
              subject={subject}
              message={message}
              draftID={draftId}
              setIsSubmitted={setIsSubmitted}
              setSubject={setSubject}
              setMessage={setMessage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComposeMessage