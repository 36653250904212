import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, Search } from 'react-feather'
import SubscribersList from '../../components/SubscribersList'
import { ApiUrl } from '../../utils/constants'
import '../styles.css'

type Props = {
  changeDisplay: Function
}

const AllSubscribers = ({ changeDisplay }: Props) => {
  const [activeTab, setActiveTab] = useState<string>('whatsapp-tab');
  const [whatsappSubscribers, setWhatsappSubscribers] = useState<any>([]);
  const [emailSubscribers, setEmailSubscribers] = useState<any>([]);
  const [whatsappLength, setWhatsappLength] = useState<number>(0);
  const [emailLength, setEmailLength] = useState<number>(0);
//   const [search, setSearch] = useState<string>('');
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  }

  const handleSearchDisplay = () => {
    setIsSearch(!isSearch);
  }

  const getSubscribersList = async () => {
    const response = await axios.get(`${ApiUrl}/user/subscriber`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('_apitoken')}`
      }
    });
    if (response) {
      if (response.data.status === true) {
        const whatsappData: any = [];
        const emailData: any = [];
        const responseData = response.data.data;
        responseData.data.forEach((subscriber: any) => {
          if (subscriber.channel === 'whatsapp') {
            whatsappData.push(subscriber);
          }
          if (subscriber.channel === 'email') {
            emailData.push(subscriber);
          }
        });
        setWhatsappSubscribers(whatsappData);
        setEmailSubscribers(emailData);
        setWhatsappLength(whatsappData.length);
        setEmailLength(emailData.length);
      }
    }
  }

  useEffect(() => {
    getSubscribersList();
  }, []);

  return (
    <div className="edit-profile__wrapper">
      <div className="back-header__wrapper">
        <div className="back-header__container">
          <div className="back-btn" onClick={() => changeDisplay()}>
            <ArrowLeft />
          </div>
          <div className="page-title flex">
            <h5>Subscribers</h5>
            <button className="title-action" onClick={handleSearchDisplay}>
              <Search />
            </button>
          </div>
        </div>
      </div>
      <div className="newsletter-section">
        <div className="admin-details__wrapper">
          <div className="admin-details__content">
            <div className="tab-header__section section-one">
              <div className={activeTab === 'whatsapp-tab' ? "header-one active" : "header-one"} onClick={() => handleTabChange('whatsapp-tab')}>
                WhatsApp ({whatsappLength})
              </div>
              <div className={activeTab === 'email-tab' ? "header-three active" : "header-three"} onClick={() => handleTabChange('email-tab')}>
                Email ({emailLength})
              </div>
            </div>
            {isSearch ? (
              <div className="search-section">
                <div className="search-icon">
                  <Search />
                </div>
                <div className="search-section__wrapper">
                  <input type="text" name="" id="" placeholder='Search Messages' />
                </div>
              </div>
            ) : null}
            <div className="tab-content__section">
              {activeTab === 'whatsapp-tab' ? (
                <SubscribersList subscribers={whatsappSubscribers} />
              ) : null}
              {activeTab === 'email-tab' ? (
                <SubscribersList subscribers={emailSubscribers} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllSubscribers