import React from 'react'
import { User } from 'react-feather'

type Props = {
  subscribers: Array<any>
}

const SubscribersList = ({ subscribers }: Props) => {
  return (
    <div className="subscribers-list__wrapper">
      <div className="subscribers-list">
        {subscribers.length > 0 && subscribers.map((item: any) => (
          <div className="subscriber" key={item.uid}>
            <div className="user">
              <div className="user-image">
                <User />
              </div>
              <div className="user-details">
                <p className="details-name">
                  {item.firstname}
                </p>
                <p>{item.channel === 'email' ? item.email : item.phonenumber}</p>
              </div>
            </div>
          </div>
        ))}
        {subscribers.length === 0 ? (
          <div className="no-subscriber-section">
            <p>You have no subscribers yet. When you do, they&apos;ll show up here.</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SubscribersList