import React, { useEffect, useState } from 'react'
import { ChevronRight } from 'react-feather'
import BackHeader from '../../components/BackHeader'
import Edit from '../../assets/icons/edit.svg'
import Mail from '../../assets/icons/mail.svg'
import Group from '../../assets/icons/group.svg'
// import Settings from '../../assets/icons/settings.svg'
import '../styles.css'
import axios from 'axios'
import { ApiUrl } from '../../utils/constants'
import Cookies from 'js-cookie'

type Props = {
  composeMessage: Function,
  messages: Function,
  subscribers: Function,
}

const DefaultNewsletter = ({ composeMessage, messages, subscribers }: Props) => {
  const [subscribersLength, setSubscribersLength] = useState<number>(0);
//   const [subscribers, setSubscribers] = useState<Array<any>>([]);

  const getSubscribersList = async () => {
    const response = await axios.get(`${ApiUrl}/user/subscriber`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('_apitoken')}`
      }
    });
    if (response) {
      if (response.data.status === true) {
        setSubscribersLength(response.data.data.meta.total);
        // setSubscribers(response.data.data.data);
      }
    }
  }

  useEffect(() => {
    getSubscribersList();
  }, []);

  return (
    <div className='edit-profile__wrapper'>
      <BackHeader title="Newsletter Options" isPreviousPage={true} changeDisplay={() => {}} />
      <div className="newsletter-section">
        <div className="admin-details__wrapper">
          <div className="admin-details__content">
            <div className="newsletter-card" onClick={() => composeMessage()}>
              <div className="card-content">
                <div className="card-icon">
                  <img src={Edit} alt="edit" />
                </div>
                <div className="card-title">
                  <h5>Compose Message</h5>
                  {/* <p>Lorem ipsum dolor sit ameconset adipiscing dulce elit.</p> */}
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div>
            <div className="newsletter-card" onClick={() => messages()}>
              <div className="card-content">
                <div className="card-icon">
                  <img src={Mail} alt="mail" />
                </div>
                <div className="card-title">
                  <h5>Messages</h5>
                  <p>View your sent messages and all unsent drafts here.</p>
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div>
            <div className="newsletter-card" onClick={() => subscribers()}>
              <div className="card-content">
                <div className="card-icon">
                  <img src={Group} alt="group" />
                </div>
                <div className="card-title">
                  <h5>Subscribers ({subscribersLength})</h5>
                  <p>View the list of all your subscribers.</p>
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div>
            {/* <div className="newsletter-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src={Settings} alt="settings" />
                </div>
                <div className="card-title">
                  <h5>Settings</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              </div>
              <div className="card-action">
                <ChevronRight />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultNewsletter