import React, { useState } from 'react'
import AllMessages from './newsletterPages/AllMessages';
import AllSubscribers from './newsletterPages/AllSubscribers';
import ComposeMessage from './newsletterPages/ComposeMessage';
import DefaultNewsletter from './newsletterPages/DefaultNewsletter';
import MessageType from './newsletterPages/MessageType';
import './styles.css'

const Newsletter = () => {
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [isMessageType, setIsMessageType] = useState<boolean>(false);
  const [isComposeMessage, setIsComposeMessage] = useState<boolean>(false);
  const [isMessages, setIsMessages] = useState<boolean>(false);
  const [isSubscribers, setIsSubscribers] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<string>('');

  const handleMessageTypeDisplay = () => {
    setIsDefault(false);
    setIsMessageType(true);
    setIsComposeMessage(false);
    setIsMessages(false);
    setIsSubscribers(false);
  }

  const handleRevertToDefaultDisplay = () => {
    setIsDefault(true);
    setIsMessageType(false);
    setIsComposeMessage(false);
    setIsMessages(false);
    setIsSubscribers(false);
  }

  const handleComposeMessageDisplay = (value: string) => {
    setMessageType(value);
    setIsDefault(false);
    setIsMessageType(false);
    setIsComposeMessage(true);
    setIsMessages(false);
    setIsSubscribers(false);
  }

  const handleMessagesDisplay = () => {
    setIsDefault(false);
    setIsMessageType(false);
    setIsComposeMessage(false);
    setIsMessages(true);
    setIsSubscribers(false);
  }

  const handleSubscribersDisplay = () => {
    setIsDefault(false);
    setIsMessageType(false);
    setIsComposeMessage(false);
    setIsMessages(false);
    setIsSubscribers(true);
  }

  return (
    <>
      {isDefault ? (
        <DefaultNewsletter
          composeMessage={handleMessageTypeDisplay} 
          messages={handleMessagesDisplay}
          subscribers={handleSubscribersDisplay}
        />
      ) : null}
      {isMessageType ? (
        <MessageType
          changeDisplay={handleRevertToDefaultDisplay}
          composeMessage={handleComposeMessageDisplay}
        />
      ) : null}
      {isComposeMessage ? (
        <ComposeMessage
          messageMode={messageType}
          messageContent={null}
          changeDisplay={handleMessageTypeDisplay}
        />
      ) : null}
      {isMessages ? (
        <AllMessages
          changeDisplay={handleRevertToDefaultDisplay}
        />
      ) : null}
      {isSubscribers ? (
        <AllSubscribers
          changeDisplay={handleRevertToDefaultDisplay}
        />
      ) : null}
    </>
  )
}

export default Newsletter