import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AppLoader from './components/AppLoader';
import PreviewProvider from './providers/PreviewProvider';
import ThemeProvider from './providers/ThemeProvider';
import UserDetailsProvider from './providers/UserDetailsProvider';
import PrivateRoute from './utils/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserHome from './pages/UserHome';
import Newsletter from './pages/Newsletter';
import PageNotFound from './pages/PageNotFound';
import { HelmetProvider } from 'react-helmet-async';

const AdminHome = React.lazy(() => import('./pages/AdminHome'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const EditProfile = React.lazy(() => import('./pages/EditProfile'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const HelpSupport = React.lazy(() => import('./pages/HelpSupport'));
const Login = React.lazy(() => import( './pages/Login'));
const Signup = React.lazy(() => import( './pages/Signup'));

function App() {
  return (
    <Router>
    <div className="App">
      <HelmetProvider>
        <ThemeProvider>
          <PreviewProvider>
            <UserDetailsProvider>
              <React.Suspense fallback={<AppLoader />}>
                <Routes>
                  <Route element={<PrivateRoute />}>
                    <Route path="/" element={<AdminHome />} />
                    <Route path="/edit-profile" element={<EditProfile />} />
                    <Route path="/support" element={<HelpSupport />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/newsletter" element={<Newsletter />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/reset-password" element={<ForgotPassword />} />
                  <Route path="/:userName" element={<UserHome />} />
                  <Route path="/not-found" element={<PageNotFound />} />
                  <Route path="*" element={<Navigate to="/not-found" replace />} />
                </Routes>
              </React.Suspense>
            </UserDetailsProvider>
          </PreviewProvider>
        </ThemeProvider>
      </HelmetProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
    </Router>
  );
}

export default App;
