import React, { useContext, useState } from 'react'
import { PreviewContext } from '../providers/PreviewProvider'
import PagechapLogo from '../assets/images/app-logo.png'
import { X } from 'react-feather';

const NewUserMode = () => {
  const { setIsNewUserMode } = useContext(PreviewContext);
  const [username, setUserName] = useState<string>('');

  const handleUserNameChange = (e: {target: HTMLInputElement}) => {
    setUserName(e.target.value);
  }

  const goToAuthScreen = () => {
    let newUserName = ''
    if (username.includes(' ')) {
     newUserName = username.replace(' ', '_');
    } else {
      newUserName = username
    }
    localStorage.setItem('username', newUserName);
    window.location.href= '/signup';
  }

  const closeMode = (e: React.MouseEvent<HTMLElement>) => {
    setIsNewUserMode(false)
  }

  return (
    <div className="preview-mode__wrapper">
      <div className="usermode-section">
        <div className="usermode-content">
          <div>
            <img src={PagechapLogo} alt="" />
            <span className="title">
              pagechap.me/
            </span>
            <input type="text" placeholder='yourusernamehere' value={username} onChange={(e) => handleUserNameChange(e)} />
          </div>
          <div className='cancel-btn' onClick={(e) => closeMode(e)}>
            <X />
          </div>
        </div>
        <p onClick={goToAuthScreen}>Create your Pagechap too!</p>
      </div>
    </div>
  )
}

export default NewUserMode