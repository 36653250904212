import React, { useContext, useEffect, useState } from 'react'
import { Moon, Sun } from 'react-feather';
import { ThemeContext, themes } from '../providers/ThemeProvider'

const ToggleMode = () => {
  const { changeTheme } = useContext(ThemeContext);
  const [lightMode, setLightMode] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.getItem('theme') !== null) {
      if (localStorage.getItem('theme') !== '') {
        setLightMode(false);
      } else {
        setLightMode(true);
      }
    }
  }, []);

  return (
    <div className="toggle-mode__wrapper" onClick={() => {
      setLightMode(!lightMode);
      changeTheme(lightMode ? themes.dark : themes.light)
    }}>
      {!lightMode ? (
        <Sun />
      ) : (
        <Moon />
      )}
    </div>
  )
}

export default ToggleMode