import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Fragment, useState } from 'react'
import Emoji from 'react-emoji-render';
import { ArrowUpRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import '../assets/css/accordion.css'
import BrandLogo from '../assets/images/app-logo.png';
import { ApiUrl } from '../utils/constants';
import UserNewsLetter from './UserNewsLetter';
import ViewImageModal from './ViewImageModal';

type Props = {
  userLinks: Array<any>,
  isSubscribe: boolean,
  newsChannel: string,
  newsHeading: string,
  newsBody: string,
  devices: string
}

const UserLinkDisplay = ({ userLinks, isSubscribe, newsChannel, newsHeading, newsBody, devices }: Props) => {
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isImageShowing, setIsImageShowing] = useState<boolean>(false);

  const addLinkViewAnalytics = async (index: string) => {
    if (devices !== '') {
      const data = {
        contentLinkUid: index,
        deviceId: devices
      }
      try {
        const response = await axios.post(`${ApiUrl}/analytic/content-media`, data);
        if (response.data.status === true) {
          console.log('success');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const updateUserView = async (index: string) => {
    setIsLoading(true);
    try {
      const response = await axios.put(`${ApiUrl}/content-link/update-view/${index}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('_apitoken')}`
          }
        }
      )
      if (response) {
        setIsLoading(false);
        if (response.data.status === true) {
          console.log(response.data);
          addLinkViewAnalytics(index);
        }
      }
    } catch (e: any) {
      setIsLoading(false);
    }
  }

  const handleContentShowing = (e: React.MouseEvent<HTMLDivElement>, value: any) => {
    setCurrentId(value.uid);
    window.open(value.url);
    updateUserView(value.uid);
  }

  const handleImageShowing = (value: any) => {
    setImageUrl(value);
    setIsImageShowing(true);
  }

  const handleImageClose = () => {
    setIsImageShowing(false);
  }

  const handleDirectToLogin = () => {
    navigate('/signup');
  }

  return (
   <div className="user-details__wrapper">
    <div className="link-display__wrapper">
      <div className="accordion__wrapper">
        {userLinks.map((item) => (
          <Fragment key={item.uid}>
            {item.input_type === 'link_block' ? (
              <div className="link__item" onClick={(e: any) => handleContentShowing(e, item)} style={{ backgroundColor: `${item.url === 'https://pagechap.me/signup' ? '#E6145B' : ''}` }}>
                <p style={{ color: `${item.url === 'https://pagechap.me/signup' ? '#FFF' : ''}`}}>{item.title}</p>
                {isLoading && currentId === item.uid ? (
                  <div className="loader" />
                ) : (
                  <div className='link-btn'>
                    <ArrowUpRight style={{ color: `${item.url === 'https://pagechap.me/signup' ? '#FFF' : ''}`}} />
                  </div>
                )}
              </div>
            ) : null}
            {item.input_type === 'text_block' ? (
              <div className="link__item">
                <div className="text-block">
                  {item.title !== null && item.title !== '' ? <h5><Emoji text={item.title} /></h5> : null}
                  {item.text !== null && item.text !== '' ? <p dangerouslySetInnerHTML={{__html: item.text}} /> : null}
                </div>
              </div>
            ) : null}
            {item.input_type === 'image_block' ? (
              <div key={item.uid} className="link__item">
                <div className="image-block">
                  <div className="image-wrapper">
                    <img src={item.url} alt={item.title} onClick={() => handleImageShowing(item.url)} />
                  </div>
                  <h5><Emoji text={item.title} /></h5>
                  <p>{item.text}</p>
                </div>
              </div>
            ): null}
            {item.input_type === 'video_block' ? (
              <div key={item.uid} className="link__item">
                <div className="video-block">
                  <div className="video-wrapper">
                    <iframe src={item.url} title={item.title} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                  <h5><Emoji text={item.title} /></h5>
                  <p>{item.text}</p>
                </div>
              </div>
            ): null}
          </Fragment>
        ))}
        {isSubscribe ? (
          <UserNewsLetter newsLetterChannel={newsChannel} newsletterHeading={newsHeading} newsletterBody={newsBody} />
        ) : null}
        {isImageShowing ? (
          <ViewImageModal imageUrl={imageUrl} closeModal={handleImageClose} />
        ) : null}
      </div>
      <div className="footer-section">
        <p>Made with</p>
        <div className="brand-logo login" onClick={handleDirectToLogin}>
          <img src={BrandLogo} alt="" />
          page<span>chap</span>
        </div>
      </div>
    </div>
   </div>
  )
}

export default UserLinkDisplay