import axios from 'axios';
import React, { FormEvent, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { ApiUrl } from '../utils/constants';

type Props = {
  newsLetterChannel: string,
  newsletterHeading: string,
  newsletterBody: string
}

const UserNewsLetter = ({ newsLetterChannel, newsletterHeading, newsletterBody }: Props) => {
  const { userName } = useParams();
  const [firstName, setFirstName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [phonenumber, setPhonenumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFirstNameChange = (e: {target: HTMLInputElement}) => {
    const val = e.target.value;
    setFirstName(val);
  }

  const handleEmailAddressChange = (e: {target: HTMLInputElement}) => {
    const val = e.target.value;
    setEmailAddress(val);
  }

  // const handlePhoneNumberChange = (e: {target: HTMLInputElement}) => {
  //   const val = e.target.value;
  //   setPhonenumber(val);
  // }
  function capitalizeFirstLetter(str: string) {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName !== '' && (emailAddress !== '' || phonenumber !== '')) {
      setIsLoading(true);
      const data = emailAddress !== '' ? {
        email: emailAddress,
        firstname: firstName,
        creativeUsername: userName
      } : {
        phonenumber,
        firstname: firstName,
        creativeUsername: userName
      }

      try {
        const response = await axios.post(`${ApiUrl}/user/newsletter-subscribe`, data);
        if (response) {
          setIsLoading(false);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setFirstName('');
            setEmailAddress('');
            setPhonenumber('+234');
          }
        }
      } catch (e: any) {
        setIsLoading(false);
        if (e.response && e.response.status === 422) {
          e.response.data.errors.forEach((item: any) => {
            if (item.field === 'phonenumber') {
              toast.error('Phone number seems to be incorrect. Please check it and try again later.');
            }
          })
        }
        if (e.response && e.response.status === 400) {
          toast.error("There seems to be an error with your subscription. It's not you, it's us.");
        }
      }
    }
  }

  return (
    <div className="newsletter__wrapper">
      <div className="newsletter-content">
        <h5>{newsletterHeading}</h5>
        <p>{capitalizeFirstLetter(newsletterBody)}</p>
        <form onSubmit={handleFormSubmit}>
          <input type="text" placeholder='Enter your first name' value={firstName} onChange={handleFirstNameChange} required />
          {newsLetterChannel === 'email' ? (
            <input type="email" name="" id="" placeholder='Enter your Email' value={emailAddress} onChange={handleEmailAddressChange} />
          ) : (
            <PhoneInput country={'ng'} value={phonenumber} onChange={(phone: string) => setPhonenumber(phone)} />
          )}
          {!isLoading ? (
            <button type="submit">Subscribe</button>
          ) : (
            <button>
              <div className="loader" />
            </button>
          )}
        </form>
      </div>
    </div>
  )
}

export default UserNewsLetter