import React from 'react'
import { ArrowLeft } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import '../assets/css/admin.css'

type Props = {
  title: String,
  isPreviousPage: Boolean,
  changeDisplay: Function
}

const BackHeader = ({ title, isPreviousPage, changeDisplay }: Props) => {
    const navigate = useNavigate();

    const handleBack = () => {
      if (isPreviousPage) {
        navigate(-1);
      } else {
        changeDisplay();
      }
    }
  return (
    <div className="back-header__wrapper">
        <div className="back-header__container">
            <div className="back-btn" onClick={handleBack}>
                <ArrowLeft />
            </div>
            <div className="page-title">
                <h5>{title}</h5>
            </div>
        </div>
    </div>
  )
}

export default BackHeader