import React from 'react'
import '../assets/css/admin.css'

const SkeletonUserHero = () => {
  return (
    <div className="page-hero__wrapper">
      <div className="hero-cover-image skeleton" />
      <div className="user-profile__wrapper">
          <div className="user-avatar__section">
            <div className="avatar-image skeleton" />
          </div>
          <div className="user-bio__section">
              {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
              <h5 className="skeleton" />
              <p className="skeleton" />
              <div className="user-social__handles">
                <div className="social-wrapper" />
                <div className="social-wrapper" />
                <div className="social-wrapper" />
                <div className="social-wrapper" />
              </div>
          </div>
      </div>
    </div>
  )
}

export default SkeletonUserHero