import axios from 'axios';
import Cookies from 'js-cookie';
import React, { FormEvent, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { UserDetailsContext } from '../providers/UserDetailsProvider';
import { ApiUrl } from '../utils/constants';
// import { Info } from 'react-feather'

type Props = {
  mode: string,
  subject: string,
  message: any,
  draftID: string
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
  setSubject: React.Dispatch<React.SetStateAction<string>>,
  setMessage: React.Dispatch<React.SetStateAction<any>>
}
const ComposeNewsletter = ({ mode, subject, message, draftID, setIsSubmitted, setSubject, setMessage }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserDetailsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [currentMode, setCurrentMode] = useState<string>(mode);

  const handleSubjectChange = (e: {target: HTMLInputElement}) => {
    const val = e.target.value;
    setSubject(val);
  }

  const handleMessageChange = (value: any) => {
    setMessage(value);
  }

  // const handleSelectChange = (e: {target: HTMLSelectElement}) => {
  //   const val = e.target.value;
  //   setCurrentMode(val);
  // }

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentUser && currentUser.newLetterChannel === 'email' && subject !== '' && message.getCurrentContent().getPlainText().length !== 0) {
      setIsLoading(true);
      const rawContentState = convertToRaw(message.getCurrentContent());

      const markup = draftToHtml(
        rawContentState
      );
      const data = draftID !== '' ? {
        subject,
        body: markup,
        draftUid: draftID
      } : {
        subject,
        body: markup
      };
      const url = '/user/send-email-broadcast';
      try {
        const response = await axios.post(`${ApiUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get('_apitoken')}`
          }
        })
        if (response) {
          setIsLoading(false);
          if (response.data.status === true) {
            toast.success('Successful');
            navigate("/");
          }
        }
      } catch (e: any) {
        setIsLoading(false);
        if (e.response.data.data.includes('subscriber')) {
          toast.error(e.response.data.data);
        }
      }
    }
    else if (currentUser && currentUser.newLetterChannel !== 'email' && message !== '') {
      setIsLoading(true);
      const rawContentState = convertToRaw(message.getCurrentContent());

      const markup = draftToHtml(
        rawContentState
      );
      const data = {
        body: markup
      };
      const url = '/user/send-whatsapp-broadcast';
      try {
        const response = await axios.post(`${ApiUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get('_apitoken')}`
          }
        })
        if (response) {
          setIsLoading(false);
          if (response.data.status === true) {
            toast.success('Successful');
            setIsSubmitted(true);
            navigate("/");
          }
        }
      } catch (e: any) {
        setIsLoading(false);
        if (e.response.data.data.includes('subscriber')) {
          toast.error(e.response.data.data);
        }
      }
    }
  }

  return (
    <div className='compose-newsletter__wrapper'>
        {/* <div className="compose-info__wrapper">
          <Info />
          <div className="info-content">
            Use <span>@firstname, @fullname</span> while writing your message to automatically include the name of the mail receiver.
          </div>
        </div> */}
        <form onSubmit={handleFormSubmit}>
          <div className="select-container">
            <span>To:</span>
            <select value={mode !== null ? mode : 'email'} disabled>
              <option value="">Select</option>
              <option value="whatsapp">WhatsApp Subscribers</option>
              <option value="email">Email Subscribers</option>
            </select>
          </div>
          {(currentUser && currentUser.newLetterChannel === 'email') || mode === 'email' ? (
            <div className="input-group">
              <label htmlFor="subject">Subject</label>
              <input type="text" placeholder='Enter Subject' value={subject} onChange={handleSubjectChange} />
            </div>
          ) : null}
          <div className="input-group">
            <label htmlFor="message">Your Message</label>
            {/* <textarea cols={30} rows={10} placeholder="Enter your message" value={message} onChange={handleMessageChange} /> */}
            <Editor
              toolbar={{
                options: ['list', 'textAlign', 'link', 'embedded', 'remove', 'history'],
                // image: {
                //   // urlEnabled: true,
                //   uploadEnabled:true,
                //   alignmentEnabled: true,
                //   defaultSize: {
                //     height: 'auto',
                //     width: 'auto',
                //   },
                //   inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                // }
              }}
              editorState={message}
              toolbarClassName="toolbar"
              wrapperClassName="wrapperClassName"
              editorClassName="editor"
              onEditorStateChange={handleMessageChange}
            />
          </div>
          {isLoading ? (
            <button type="button">
              <div className="loader" />
            </button>
          ) : (
            <button type="submit">Send Message</button>
          )}
        </form>
    </div>
  )
}

export default ComposeNewsletter